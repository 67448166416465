const idPortalApi = "dbe7ba724935e1b23b7d2751c544264c32b7e1d6";

module.exports = {
  tokenLink: idPortalApi,
  urlApi: `https://api-portais-workcenter.com.br/${idPortalApi}`,
  link: "http://www.transparenciadministrativa.com.br",
  urlADM: `https://administracaopublica.com.br`,
  linkArquivos: "http://www.transparenciadministrativa.com.br/portalimagem",
  imagemURL: "https://arquivos-api-portais.s3.sa-east-1.amazonaws.com/",

  // urlApi: `http://localhost:8004/${idPortalApi}`,
  //imagemURL: 'http://localhost:8004/uploads/', //link para a imagem

  urlEmDeploy: "https://cmbacuri.ma.gov.br/", //usada para link de compartilhamento
  dnsPortal: "cmbacuri.ma.gov.br",
  NomePortal: "Bacuri",
  ufPortal: "MA",
  NomeTipoPortal: "Câmara Municipal", //Prefeitura ou Câmara
  TipoPortal: 4, //3 = Prefeitura 4 = Câmara

  //Variável em teste (newsViewRoute)
  newsViewRoute: true,
  countNewsHome: 6, //quantidade de noticias na home
  RoutesDaily: true, //Deixa ativado as rotas para a pagina de diario
  corIcones: "#b28e08",

  //links externos
  link_youtube: `#`,
  link_localize: `https://maps.app.goo.gl/bGKUkvsNbuH5EUbE9`,
  //Mapa da pagina Municipio/Mapa
  link_mapa:
    "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d8501.083802666764!2d-45.14538273274148!3d-1.729565269062221!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x92ad57151de32bf5%3A0xef83e6a4af61fc0a!2sBacuri%2C%20MA%2C%2065270-000!5e0!3m2!1spt-BR!2sbr!4v1721832641242!5m2!1spt-BR!2sbr",
};
